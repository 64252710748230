import { useShallow } from 'zustand/react/shallow';
import { ReactComponent as Attach } from '../../../assets/icons/evergineTools/attach.svg';
import { ReactComponent as AutoCalculate } from '../../../assets/icons/evergineTools/auto-calc.svg';
import { ReactComponent as TADS } from '../../../assets/icons/evergineTools/TADS.svg';
import { ToolbarConfigProps } from '../../../common';
import { useAttachesCalculation } from '../../../hooks';
import { useOrthBoundStore } from '../../stores/useStore';
import { isAttacheType } from './tools/attaches/attacheTypes';
import { OpenAttachesPanel, OpenTadsPanel } from './tools/evergineToolbar.helper';
import { isTadType } from './tools/tads/tadsTypes';

export const attachesToolbarElement: ToolbarConfigProps = {
  icon: Attach,
  tooltipKey: 'evergineTools.attaches',
  customId: 'orthAttachesButton',
  handleClick: () => {
    OpenAttachesPanel();
  },
  isDisabled: (): boolean => {
    const { selectedAttachment, showAttachesPanel, setShowAttachesPanel } = useOrthBoundStore(
      useShallow((state) => ({
        selectedAttachment: state.selectedAttache,
        showAttachesPanel: state.showAttachesPanel,
        setShowAttachesPanel: state.setShowAttachesPanel
      }))
    );

    if (selectedAttachment && selectedAttachment.type && !isAttacheType(selectedAttachment?.type)) {
      if (showAttachesPanel) setShowAttachesPanel(false);
      return true;
    }

    return false;
  }
};

export const calcAIAttachesToolbarElement: ToolbarConfigProps = {
  icon: AutoCalculate,
  tooltipKey: 'evergineTools.autoAttachesCalc',
  labelKey: 'evergineTools.autoAttachesCalc',
  handleClick: async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useAttachesCalculation().calculateAttaches();
  }
};

export const tadsToolbarElement: ToolbarConfigProps = {
  icon: TADS,
  tooltipKey: 'evergineTools.tads',
  customId: 'orthTadsButton',
  handleClick: () => {
    OpenTadsPanel();
  },
  isDisabled: (): boolean => {
    const { selectedAttachment, showTadsPanel, setShowTadsPanel } = useOrthBoundStore(
      useShallow((state) => ({
        selectedAttachment: state.selectedAttache,
        showTadsPanel: state.showTadsPanel,
        setShowTadsPanel: state.setShowTadsPanel
      }))
    );

    if (selectedAttachment?.type && !isTadType(selectedAttachment.type)) {
      if (showTadsPanel) setShowTadsPanel(false);
      return true;
    }

    return false;
  }
};
