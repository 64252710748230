import { useCallback } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { SaveChangesHook, useCaseId, useUploadFile } from '../../shared';
import { useSaveProgress } from '../useSaveProgress';
import { blobToBase64, CaptureToUpload, FileCaptureType, FileToUploadDTO, IFileService } from '../../../shared';
import { OrthodonticsOrderedPhasesKeys } from '../../../orthodontics/components/layout';
import { CapturePosition, container, INJECTED_TYPES, ScanOrientation } from '../../../common';
import { EDITED_LOWER_MATRIX_FILE_KEY, EDITED_UPPER_MATRIX_FILE_KEY } from '../../../orthodontics/components';
import { TeethArchPosition } from '../../../models';
import { useShallow } from 'zustand/react/shallow';

const mapFileExtensionFrom = (fileType: FileCaptureType) => {
  const mapping: Partial<Record<FileCaptureType, string>> = {
    [FileCaptureType.UPPER_MODEL3D]: 'wepmd',
    [FileCaptureType.UPPER_MODEL3D_EDITED]: 'wepmd',
    [FileCaptureType.LOWER_MODEL3D]: 'wepmd',
    [FileCaptureType.LOWER_MODEL3D_EDITED]: 'wepmd',
    [FileCaptureType.UPPER_STL]: 'stl',
    [FileCaptureType.UPPER_STL_EDITED]: 'stl',
    [FileCaptureType.LOWER_STL]: 'stl',
    [FileCaptureType.LOWER_STL_EDITED]: 'stl'
  };

  return mapping[fileType] ?? '';
};

export function useSaveStlEdition(): SaveChangesHook {
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const setUpperAndLowerDentalMovements = useOrthBoundStore((state) => state.setUpperAndLowerDentalMovements);
  const { currentVersion, hasStlBeenOriented, haveGumsBeenExtruded } = useOrthBoundStore(
    useShallow((state) => ({
      currentVersion: state.currentVersion,
      hasStlBeenOriented: state.hasStlBeenOriented,
      haveGumsBeenExtruded: state.haveGumsBeenExtruded
    }))
  );
  const { saveProgressInBackend } = useSaveProgress();
  const [caseId] = useCaseId();
  const { uploadFileFromFS } = useUploadFile(caseId);
  const fileService = container.get<IFileService>(INJECTED_TYPES.IFileService);

  const uploadModelFile = async (pathInFS: string, modelType: FileCaptureType) => {
    const upperStlToUpload = {
      name: `${modelType}.${mapFileExtensionFrom(modelType)}`,
      path: pathInFS,
      type: modelType
    } as CaptureToUpload;

    await uploadFileFromFS(upperStlToUpload, currentVersion?.id);
  };

  const saveOrientedModelFiles = async () => {
    const paths = await window.App.webEventsProxy.modelTransformation.updateStl();
    const upperStlFilePath = paths[0];
    const lowerStlFilePath = paths[1];

    const contentPath = `/Content`;

    if (upperStlFilePath != null) {
      const upperWepmdFilePath = `${upperStlFilePath.split('.')[0]}.wepmd`;

      await uploadModelFile(`${contentPath}/${upperStlFilePath}`, FileCaptureType.UPPER_STL_EDITED);
      await uploadModelFile(`${contentPath}/${upperWepmdFilePath}`, FileCaptureType.UPPER_MODEL3D_EDITED);
    }

    if (lowerStlFilePath != null) {
      const lowerWepmdFilePath = `${lowerStlFilePath.split('.')[0]}.wepmd`;

      await uploadModelFile(`${contentPath}/${lowerStlFilePath}`, FileCaptureType.LOWER_STL_EDITED);
      await uploadModelFile(`${contentPath}/${lowerWepmdFilePath}`, FileCaptureType.LOWER_MODEL3D_EDITED);
    }
  };

  const saveOrientedScanMatrix = async (scanOrientation: ScanOrientation, key: string) => {
    const json = JSON.stringify(scanOrientation);
    const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
    const base64 = await blobToBase64(blob);

    const fileDto: FileToUploadDTO = {
      key: key,
      mimeType: 'text/plain;charset=utf-8',
      originalName: `${key}.json`,
      file: base64,
      totalSize: 0
    };

    await fileService.uploadFile(caseId, currentVersion?.id, key, fileDto);
  };

  const saveOrientedTransformationMatrices = async () => {
    const transformationMatrices = await window.App.webEventsProxy.modelTransformation.scanOrientation();
    const upperTransformationMatrix = transformationMatrices.find((tm) => tm.scanType === TeethArchPosition.UPPER);
    if (!!upperTransformationMatrix) {
      await saveOrientedScanMatrix(upperTransformationMatrix, EDITED_UPPER_MATRIX_FILE_KEY);
    }

    const lowerTransformationMatrix = transformationMatrices.find((tm) => tm.scanType === TeethArchPosition.LOWER);
    if (!!lowerTransformationMatrix) {
      await saveOrientedScanMatrix(lowerTransformationMatrix, EDITED_LOWER_MATRIX_FILE_KEY);
    }
  };

  const saveChanges = useCallback(async (): Promise<boolean> => {
    setWebBusy(true);
    let response = true;
    try {
      if (hasStlBeenOriented || haveGumsBeenExtruded) {
        await saveOrientedModelFiles();
        await saveOrientedTransformationMatrices();
      }

      await saveProgressInBackend(null, OrthodonticsOrderedPhasesKeys.StlEdition);
    } catch (error) {
      response = false;
    } finally {
      setWebBusy(false);
      return Promise.resolve(response);
    }
  }, [setUpperAndLowerDentalMovements, saveProgressInBackend, hasStlBeenOriented, haveGumsBeenExtruded]);

  return { saveChanges, autoSaveChanges: saveChanges };
}
