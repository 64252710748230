import { StateCreator } from 'zustand';
import { DentalMovementGroup, IprLabel, ToothHoverData, ToothTransformInfo } from '../../../common';
import { EvolutionStep } from '../../components';

export interface DentalMovementsSlice {
  upperDentalMovements: DentalMovementGroup;
  setUpperDentalMovements: (data: DentalMovementGroup) => void;
  lowerDentalMovements: DentalMovementGroup;
  setLowerDentalMovements: (data: DentalMovementGroup) => void;
  setUpperAndLowerDentalMovements: (
    upperDentalMovements: DentalMovementGroup,
    lowerDentalMovements: DentalMovementGroup
  ) => void;
  showMiniOcclusogram: boolean;
  setShowMiniOcclusogram: (newValue: boolean) => void;
  selectedTeethId: number[];
  setSelectedTeethId: (newValue: number[]) => void;
  toothHoverData: ToothHoverData;
  setToothHoverData: (newValue: ToothHoverData) => void;
  isCasePublished: boolean;
  setIsCasePublished: (newValue: boolean) => void;
  clientCanPublish: boolean;
  setClientCanPublish: (newValue: boolean) => void;
  clientCanDownloadSTL: boolean;
  setClientCanDownloadSTL: (newValue: boolean) => void;
  dentalMovementsSteps: EvolutionStep[];
  setDentalMovementsSteps: (newValue: EvolutionStep[]) => void;
  dentalMovementsApplyIPRList: IprLabel[];
  setDentalMovementsApplyIPRList: (newValue: IprLabel[]) => void;
}

export const createDentalMovementsSlice: StateCreator<DentalMovementsSlice, [], [], DentalMovementsSlice> = (set) => ({
  upperDentalMovements: null,
  lowerDentalMovements: null,
  showMiniOcclusogram: false,
  selectedTeethId: [],
  toothHoverData: null,
  isCasePublished: false,
  clientCanPublish: false,
  clientCanDownloadSTL: false,
  setUpperDentalMovements: (upperDentalMovements: DentalMovementGroup) => set((_) => ({ upperDentalMovements })),
  setLowerDentalMovements: (lowerDentalMovements: DentalMovementGroup) => set((_) => ({ lowerDentalMovements })),
  setShowMiniOcclusogram: (showMiniOcclusogram: boolean) => set((_) => ({ showMiniOcclusogram })),
  setSelectedTeethId: (selectedToothId: number[]) => set((_) => ({ selectedTeethId: selectedToothId })),
  setToothHoverData: (toothHoverData: ToothHoverData) => set((_) => ({ toothHoverData })),
  setIsCasePublished: (isCasePublished: boolean) => set((_) => ({ isCasePublished })),
  setClientCanPublish: (clientCanPublish: boolean) => set((_) => ({ clientCanPublish })),
  setClientCanDownloadSTL: (clientCanDownloadSTL: boolean) => set((_) => ({ clientCanDownloadSTL })),
  setUpperAndLowerDentalMovements: (
    upperDentalMovements: DentalMovementGroup,
    lowerDentalMovements: DentalMovementGroup
  ) => set((_) => ({ upperDentalMovements, lowerDentalMovements })),
  dentalMovementsSteps: [],
  setDentalMovementsSteps: (dentalMovementsSteps: EvolutionStep[]) => set((_) => ({ dentalMovementsSteps })),
  dentalMovementsApplyIPRList: [],
  setDentalMovementsApplyIPRList: (dentalMovementsApplyIPRList: IprLabel[]) =>
    set((_) => ({ dentalMovementsApplyIPRList }))
});
