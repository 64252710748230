import { useCallback } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { SaveChangesHook } from '../../shared';
import { useSaveProgress } from '../useSaveProgress';
import { OrthodonticsOrderedPhasesKeys } from '../../../orthodontics/components/layout';

export function useSaveTreatment(): SaveChangesHook {
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const { setUpperAndLowerDentalMovements } = useOrthBoundStore((state) => ({
    setUpperAndLowerDentalMovements: state.setUpperAndLowerDentalMovements
  }));
  const { saveProgressInBackend } = useSaveProgress();

  const handleSave = useCallback(
    async (isAutoSave = false): Promise<boolean> => {
      setWebBusy(true);

      const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
      setUpperAndLowerDentalMovements(upperMovements, lowerMovements);

      await saveProgressInBackend(
        { lower: lowerMovements, upper: upperMovements },
        OrthodonticsOrderedPhasesKeys.Treatment,
        null,
        isAutoSave
      );

      setWebBusy(false);
      return true;
    },
    [setUpperAndLowerDentalMovements, saveProgressInBackend]
  );

  return {
    saveChanges: () => handleSave(false),
    autoSaveChanges: () => handleSave(true)
  };
}
