import { useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';

interface ToolPanelDraggableContainerProps {
  handlerElementClass: string;
  panelSize?: number;
  toolPosition?: DOMRect;
  toolPositionCorrection?: { x: number; y: number };
  children: React.ReactNode;
}

export function ToolPanelDraggableContainer(props: ToolPanelDraggableContainerProps) {
  const { toolPosition, panelSize, handlerElementClass, toolPositionCorrection, children } = props;
  const defaultTimeLineHeight = 40;

  const panelRef = useRef<HTMLDivElement | null>(null);
  const [panelHeight, setPanelHeight] = useState(0);

  const timelineHeight = useMemo(() => {
    return document.getElementById('timeline-id')?.offsetHeight ?? defaultTimeLineHeight;
  }, []);

  // Get the height of the panel
  useEffect(() => {
    if (panelRef.current?.firstChild instanceof HTMLElement) {
      setPanelHeight(panelRef.current.firstChild.offsetHeight);
    }
  }, [panelRef.current]);

  const pixelRatio = window.devicePixelRatio || 1;
  const scale = Math.max(0.8, 1 / pixelRatio);
  const windowHeight = window.innerHeight;

  let posY = toolPosition?.top ?? 0;
  const scaledHeight = panelHeight * scale;

  let transformStyle: React.CSSProperties = {
    transform: `translate(${toolPosition.x - panelSize * scale}px, ${posY}px) scale(${scale})`,
    transformOrigin: 'top left'
  };

  const correction = toolPositionCorrection ?? { x: 0, y: 0 };
  // If the panel is too low, move it up absolute
  if (posY + scaledHeight + timelineHeight + correction?.y > windowHeight) {
    posY = windowHeight - scaledHeight - correction?.y;
    transformStyle = {
      position: 'absolute',
      left: `${toolPosition.x + correction?.x - panelSize * scale}px`,
      top: `${posY}px`,
      transform: `scale(${scale})`,
      transformOrigin: 'top left'
    };
  }

  return (
    <div ref={panelRef} style={transformStyle}>
      <Draggable handle={`.${handlerElementClass}`}>{children}</Draggable>
    </div>
  );
}
