import { useCallback } from 'react';
import { DentalMovements, Segmentation } from '../../common';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { editCasePhase, updateTreatmentPlanificationMovements } from '../../common/utils';
import { OrthodonticsOrderedPhasesKeys } from '../../orthodontics/components/layout';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { CaseVersionState } from '../../shared';
import { CasePhase } from '../../shared/caseStatus';
import { useGetDentalMovements, useUtils } from '../shared';

export function useSaveProgress() {
  const { currentVersion, setIsCasePublished } = useOrthBoundStore((state) => ({
    currentVersion: state.currentVersion,
    setIsCasePublished: state.setIsCasePublished
  }));
  const { updateIsCaseReseting, isCaseReseting } = useCommonBoundStore((state) => ({
    updateIsCaseReseting: state.updateIsCaseReseting,
    isCaseReseting: state.isCaseReseting
  }));
  const { updateTreatmentState } = useGetDentalMovements();
  const { uncompleteNextPhases } = useUtils();
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);

  const saveProgressInBackend = useCallback(
    async (
      dentalMov: DentalMovements,
      phase: OrthodonticsOrderedPhasesKeys,
      segmentation: Segmentation = null,
      autosave = false
    ): Promise<void> => {
      if (!currentVersion) {
        return;
      }

      const buildPhase: CasePhase = {
        id: phase,
        name: phase,
        completionDate: new Date(),
        editionDate: new Date()
      };

      const versionId = currentVersion.id.toString();
      const caseId = currentVersion.caseId;

      await editCasePhase(caseId, versionId, buildPhase);

      if (isCaseReseting && !autosave) {
        setCanUndo(false);
        updateIsCaseReseting(false);
        await uncompleteNextPhases();
        await updateTreatmentState(currentVersion.caseId, currentVersion, CaseVersionState.designing, true);
        setIsCasePublished(false);
      }

      if (dentalMov) {
        await updateTreatmentPlanificationMovements(caseId, currentVersion, dentalMov, segmentation);
      }
    },
    [currentVersion, isCaseReseting]
  );

  return { saveProgressInBackend };
}
