import { useCallback, useEffect, useMemo } from 'react';
import { ToolPanelDraggableContainer } from '../ToolPanelDraggableContainer';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { useTranslation } from 'react-i18next';
import './iprPanel.scss';
import { useIprCalculation } from '../../../../../hooks/shared/useIprCalculation';
import { useSaveTreatment } from '../../../../../hooks';
import { useShallow } from 'zustand/react/shallow';

const PANEL_SIZE = 215;
const HANDLER_DRAG_ELEMENT_CLASS = 'handleIprPanel';

export function IprPanel() {
  const [t] = useTranslation();

  const { setShowTadsPanel, setShowAttachesPanel, setShowIprPanel, setShowMovementPanel } = useOrthBoundStore(
    useShallow((state) => ({
      setShowTadsPanel: state.setShowTadsPanel,
      setAttachActive: state.setAttachActive,
      setShowAttachesPanel: state.setShowAttachesPanel,
      setShowIprPanel: state.setShowIprPanel,
      setShowMovementPanel: state.setShowMovements
    }))
  );
  const { calculateIpr } = useIprCalculation();
  const { autoSaveChanges } = useSaveTreatment();

  const toolPosition = useMemo(() => {
    return document.getElementById('iprPanelButton')?.getBoundingClientRect();
  }, []);

  const handleIprCalculation = useCallback(async () => {
    await calculateIpr();

    // Automatic Save treatment
    await autoSaveChanges();
  }, [calculateIpr]);

  useEffect(() => {
    setShowAttachesPanel(false);
    setShowTadsPanel(false);
    setShowMovementPanel(false);
  }, []);

  return (
    <>
      {!!toolPosition && (
        <ToolPanelDraggableContainer
          panelSize={PANEL_SIZE}
          toolPosition={toolPosition}
          handlerElementClass={HANDLER_DRAG_ELEMENT_CLASS}
        >
          <div className="ipr-panel">
            <div className="ipr-panel__header">
              <div className={`ipr-panel__title ${HANDLER_DRAG_ELEMENT_CLASS}`}>
                <div>{t('evergineTools.ipr')}</div>
              </div>

              <button type="button" className="ipr-panel__close" onClick={() => setShowIprPanel(false)}>
                <CloseIcon className="clickable" />
              </button>
            </div>
            <div className="ipr-panel__separator" />
            <div className="ipr-panel__content">
              <div className="ipr-panel__buttons-container">
                <button type="button" onClick={() => handleIprCalculation()} className="ipr-panel__button">
                  {t('evergineTools.autoIpr')}
                </button>
              </div>
            </div>
          </div>
        </ToolPanelDraggableContainer>
      )}
    </>
  );
}
