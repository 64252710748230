import JSZip from 'jszip';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DentalMovementGroup,
  INJECTED_TYPES,
  Segmentation,
  container,
  SegmentationFailReason,
  SegmentationProcessingResult
} from '../../../common';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { OrthodonticsOrderedPhasesKeys } from '../../../orthodontics/components/layout';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { IFileService } from '../../../shared';
import { ModalTypes, TypesActions } from '../../../surgeries/components/layout';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { SaveChangesHook, useCaseId } from '../../shared';
import { useBase64Converter } from '../../useBase64Converter';
import { useFiles } from '../../useFiles';
import { useSaveProgress } from '../useSaveProgress';

type SegmentationResult = {
  upper: DentalMovementGroup;
  lower: DentalMovementGroup;
  segmentation: Segmentation;
};

export function useSaveSegmentation(): SaveChangesHook {
  const fileService = container.get<IFileService>(INJECTED_TYPES.IFileService);
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const { currentVersion, setUpperAndLowerDentalMovements, setAreSegmentationModelsInFS, isNavigationToNextPhase } =
    useOrthBoundStore((state) => ({
      currentVersion: state.currentVersion,
      setUpperAndLowerDentalMovements: state.setUpperAndLowerDentalMovements,
      setAreSegmentationModelsInFS: state.setAreSegmentationModelsInFS,
      isNavigationToNextPhase: state.isNavigationToNextPhase
    }));

  const { toggleModalIsOpened, setModalMessage, setModalTitle } = useCommonBoundStore((state) => ({
    toggleModalIsOpened: state.toggleModalIsOpened,
    setModalMessage: state.setModalMessage,
    setModalTitle: state.setModalTitle
  }));
  const { blobToBase64 } = useBase64Converter();
  const [caseId] = useCaseId();
  const { isFile } = useFiles();
  const [t] = useTranslation();
  const { saveProgressInBackend } = useSaveProgress();

  const dynamicModelsFullPath = `/Content/DynamicModels`;

  const processSegmentationResult = async (): Promise<SegmentationResult> => {
    return new Promise<SegmentationResult>((resolve, reject) => {
      setTimeout(async () => {
        try {
          const result = await window.App.webEventsProxy.segmentation.processSegmentation();

          if (result == null) {
            return resolve(null);
          }

          if (result.segmentationFailReason != SegmentationFailReason.None) {
            return reject(result);
          }

          const segmentation: Segmentation = {
            upper: result.upperSegmentationData,
            lower: result.lowerSegmentationData
          };

          return resolve({
            lower: result.lowerMovements,
            upper: result.upperMovements,
            segmentation: segmentation
          });
        } catch (error) {
          console.error(error);
          return reject(error);
        }
      }, 0);
    });
  };

  const areWepmdFilesInFS = (upperDentalMovements: DentalMovementGroup, lowerDentalMovements: DentalMovementGroup) => {
    let areFilesInFS = true;

    let filesList: any = [];

    if (upperDentalMovements) {
      filesList = filesList.concat(...upperDentalMovements.teeth);
      filesList = filesList.concat(upperDentalMovements.gum);
    }

    if (lowerDentalMovements) {
      filesList = filesList.concat(...lowerDentalMovements.teeth);
      filesList = filesList.concat(lowerDentalMovements.gum);
    }

    filesList.forEach((e: any) => {
      const fileFSPath = `${dynamicModelsFullPath}/${e.id}.wepmd`;
      if (!isFile(fileFSPath)) {
        console.error(`File ${e.id}.wepmd not found in ${dynamicModelsFullPath}`);
        areFilesInFS = false;
      }
    });

    return areFilesInFS;
  };

  const buildTeethGumZip = useCallback(
    async (upperDentalMovements: DentalMovementGroup, lowerDentalMovements: DentalMovementGroup) => {
      try {
        const zip = new JSZip();

        if (upperDentalMovements) {
          upperDentalMovements.teeth.forEach((tooth) => {
            const toothFile = Module.FS.readFile(`/Content/DynamicModels/${tooth.id}.wepmd`);
            zip.file(`${tooth.id}.wepmd`, toothFile);
          });
          const upperGumFile = Module.FS.readFile(`/Content/DynamicModels/${upperDentalMovements.gum.id}.wepmd`);
          zip.file(`${upperDentalMovements.gum.id}.wepmd`, upperGumFile);
        }

        if (lowerDentalMovements) {
          lowerDentalMovements.teeth.forEach((tooth) => {
            const toothFile = Module.FS.readFile(`/Content/DynamicModels/${tooth.id}.wepmd`);
            zip.file(`${tooth.id}.wepmd`, toothFile);
          });
          const lowerGumFile = Module.FS.readFile(`/Content/DynamicModels/${lowerDentalMovements.gum.id}.wepmd`);
          zip.file(`${lowerDentalMovements.gum.id}.wepmd`, lowerGumFile);
        }
        return await zip.generateAsync({ type: 'blob' });
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    []
  );

  const saveTeethGumZip = useCallback(
    async (
      upperDentalMovements: DentalMovementGroup,
      lowerDentalMovements: DentalMovementGroup,
      currentVersionId: string
    ) => {
      const zipFile = await buildTeethGumZip(upperDentalMovements, lowerDentalMovements);

      if (zipFile && currentVersionId) {
        const base64content = (await blobToBase64(zipFile)).replace('data:application/zip;base64,', '');

        await fileService.uploadFile(caseId, currentVersionId, 'teeth-zip', {
          key: 'teeth-zip',
          originalName: 'teeth.zip',
          totalSize: zipFile.size,
          mimeType: 'application/octet-stream',
          file: base64content
        });
      }
    },
    [caseId]
  );

  const saveChanges = useCallback(
    async (isNextPhase): Promise<boolean> => {
      if (currentVersion === null) {
        return Promise.resolve(false);
      }
      if (!isNextPhase) {
        return Promise.resolve(true);
      }

      let response;
      try {
        setWebBusy(true);

        const result = await processSegmentationResult();

        if (result !== null) {
          setUpperAndLowerDentalMovements(result.upper, result.lower);

          if (areWepmdFilesInFS(result.upper, result.lower)) {
            await saveTeethGumZip(result.upper, result.lower, currentVersion.id);
            setAreSegmentationModelsInFS(true);
          }

          await saveProgressInBackend(
            { lower: result.lower, upper: result.upper },
            OrthodonticsOrderedPhasesKeys.TeethSegmentation,
            result.segmentation
          );
        }
        response = true;
      } catch (error) {
        if ((error as SegmentationProcessingResult).segmentationFailReason == SegmentationFailReason.NotEnoughTeeth) {
          setModalTitle(t(`modal.error.segmentation.title`));
          setModalMessage(t(`modal.error.segmentation.notEnoughTeethToSegmentate`));
          toggleModalIsOpened(ModalTypes.ModalError);
        }
        response = false;
      } finally {
        setWebBusy(false);
        return Promise.resolve(response);
      }
    },
    [caseId, currentVersion, saveProgressInBackend, setUpperAndLowerDentalMovements, isNavigationToNextPhase]
  );

  return {
    saveChanges,
    autoSaveChanges: () => saveChanges(false)
  };
}
