import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApplyIprState,
  Attachment,
  AttachmentState,
  DentalMovementGroup,
  InterdentalDistance,
  IprLabel,
  Step,
  Tooth,
  ToothMovement
} from '../../common';
import {
  EvolutionAttachment,
  EvolutionInterdentalDistance,
  EvolutionStep,
  EvolutionTooth
} from '../../orthodontics/components';
import { Constants, DentalMovementDTO } from '../../shared';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useMovementTable } from '../shared/useMovementTable';
import { useEvergineStore } from 'evergine-react';
import { useShallow } from 'zustand/react/shallow';
import { useUtils } from '../shared';
import { useToothTransformStore } from '../../orthodontics/stores/useToothTransformStore';

export function useDentalMovementsStepsManager(checkMovementUpdates = false) {
  const [loading, setLoading] = useState(false);
  const { lowerDentalMovements, upperDentalMovements } = useOrthBoundStore(
    useShallow((state) => ({
      lowerDentalMovements: state.lowerDentalMovements,
      upperDentalMovements: state.upperDentalMovements
    }))
  );

  const { getMovementsTable, getRelativeDentalMovementsByStep, isOverMovement, isThereOppositeMovement } =
    useMovementTable();

  const { evergineReady } = useEvergineStore();
  const {
    stageIsLoaded,
    dentalMovementsSteps,
    setDentalMovementsSteps,
    dentalMovementsApplyIPRList,
    setDentalMovementsApplyIPRList
  } = useOrthBoundStore((state) => ({
    stageIsLoaded: state.stageIsLoaded,
    dentalMovementsSteps: state.dentalMovementsSteps,
    dentalMovementsApplyIPRList: state.dentalMovementsApplyIPRList,
    setDentalMovementsSteps: state.setDentalMovementsSteps,
    setDentalMovementsApplyIPRList: state.setDentalMovementsApplyIPRList
  }));

  const maxStoreStepsLength = useMemo(
    () => Math.max(upperDentalMovements?.steps?.length || 0, lowerDentalMovements?.steps?.length || 0),
    [upperDentalMovements, lowerDentalMovements]
  );

  const { teethTransformDataList } = useToothTransformStore(
    useShallow((state) => ({
      teethTransformDataList: state.teethTransformDataList
    }))
  );

  const { fixFloat } = useUtils();

  function calculateTeeth(
    step: Step,
    allTeeth: Tooth[],
    isUpperPassiveAligner: boolean,
    isLowerPassiveAligner: boolean
  ): EvolutionTooth[] {
    return step.toothMovements.map((toothMovement: ToothMovement) => {
      const tooth = allTeeth.find((tooth: Tooth) => tooth.id === toothMovement.toothId);
      return {
        fdi: tooth?.fdi,
        isExtracted: tooth?.extractedInStep?.valueOf() <= step.stepIndex || false,
        isMoved: toothMovement.movedInCurrentStep,
        isPassiveAligner: tooth?.fdi < 30 ? isUpperPassiveAligner : isLowerPassiveAligner
      };
    });
  }

  const calculateAttachmentStateForStep = useCallback(
    (attachment: Attachment, step: Step) => {
      if (attachment.firstStep === step.stepIndex) {
        if (
          attachment.lastStep === step.stepIndex ||
          (attachment.lastStep === Constants.maxLastStepForAttachments && step.stepIndex === maxStoreStepsLength - 1)
        ) {
          return AttachmentState.Both;
        } else {
          return AttachmentState.Start;
        }
      } else if (
        attachment.lastStep === step.stepIndex ||
        (attachment.lastStep === Constants.maxLastStepForAttachments && step.stepIndex === maxStoreStepsLength - 1)
      ) {
        return AttachmentState.End;
      } else {
        return AttachmentState.None;
      }
    },
    [maxStoreStepsLength]
  );

  const calculateAttachments = useCallback(
    (step: Step, allTeeth: Tooth[]): EvolutionAttachment[] => {
      const allAttachments = [
        ...(lowerDentalMovements?.attachments ?? []),
        ...(upperDentalMovements?.attachments ?? [])
      ];

      return Object.values(
        allAttachments
          .map(
            (attachment: Attachment): EvolutionAttachment => ({
              toothFdi: allTeeth.find((tooth: Tooth) => tooth.id === attachment.toothId)?.fdi,
              state: calculateAttachmentStateForStep(attachment, step)
            })
          )
          .reduce((acc: any, { toothFdi, state }) => {
            if (acc[toothFdi]) {
              acc[toothFdi].state = mergeAttachmentState(acc[toothFdi].state, state);
            } else {
              acc[toothFdi] = { toothFdi, state };
            }
            return acc;
          }, {})
      );
    },
    [calculateAttachmentStateForStep, lowerDentalMovements?.attachments, upperDentalMovements?.attachments]
  );

  function mergeAttachmentState(state1: AttachmentState, state2: AttachmentState): AttachmentState {
    if (state1 === AttachmentState.None) return state2;
    if (state2 === AttachmentState.None) return state1;
    if (state1 === state2) return state1;
    return AttachmentState.Both;
  }

  const calculateInterdentalDistances = useCallback(
    (step: Step, allTeeth: Tooth[], allIprLabels: IprLabel[]): EvolutionInterdentalDistance[] => {
      return step.ipr.map((interdentalDistance: InterdentalDistance) => {
        const leftTooth = allTeeth.find((tooth: Tooth) => tooth.id === interdentalDistance.leftToothId);
        const rightTooth = allTeeth.find((tooth: Tooth) => tooth.id === interdentalDistance.rightToothId);
        const iprLabel = allIprLabels?.find((l) => l.fdiLeft === leftTooth.fdi);
        const applyIprStepIndex = iprLabel?.applyStepIndex;
        const applyIprState = calculateApplyIprState(applyIprStepIndex, step.stepIndex);
        return {
          leftToothFdi: leftTooth.fdi,
          rightToothFdi: rightTooth.fdi,
          distance:
            applyIprState !== ApplyIprState.None ? fixFloat(iprLabel.value) : fixFloat(interdentalDistance.distance),
          applyIprState: applyIprState,
          isSpaceVisible: applyIprState === ApplyIprState.None && interdentalDistance.distance > 0
        };
      });
    },
    []
  );

  function calculateApplyIprState(applyIprStepIndex: number | undefined, stepIndex: number): ApplyIprState {
    if (applyIprStepIndex === undefined) {
      return ApplyIprState.None;
    } else if (applyIprStepIndex === stepIndex) {
      return ApplyIprState.ApplyIpr;
    } else if (stepIndex < applyIprStepIndex) {
      return ApplyIprState.PreIpr;
    } else {
      return ApplyIprState.PostIpr;
    }
  }

  const groupObjectsByStepIndex = (lowerSteps: Step[], upperSteps: Step[]) => {
    const groupedObjects: { [key: number]: Step } = {};

    [...(lowerSteps ?? []), ...(upperSteps ?? [])].forEach((obj) => {
      const { stepIndex, toothMovements, ipr: interdentalDistances, isKeyStep } = obj;
      if (!groupedObjects[stepIndex]) {
        groupedObjects[stepIndex] = {
          stepIndex,
          toothMovements: [],
          ipr: [],
          jawTransform: [],
          isKeyStep: false
        };
      }

      if (Array.isArray(toothMovements)) {
        groupedObjects[stepIndex].toothMovements = groupedObjects[stepIndex].toothMovements.concat(toothMovements);
      }

      if (Array.isArray(interdentalDistances)) {
        groupedObjects[stepIndex].ipr = groupedObjects[stepIndex].ipr.concat(interdentalDistances);
      }

      groupedObjects[stepIndex].isKeyStep = groupedObjects[stepIndex].isKeyStep || isKeyStep;
    });

    return Object.values(groupedObjects);
  };

  const hasAnyOvermovement = useCallback(() => {
    return (
      dentalMovementsSteps.some((step) => step.relativeDentalMovement.some((movement) => isOverMovement(movement))) ||
      teethTransformDataList.some((t) =>
        isOverMovement({
          extrusionIntrusion: t.extrusionIntrusion,
          pureRotation: t.pureRotation,
          tip: t.tip,
          torque: t.torque,
          translationMD: t.translationMD,
          translationVL: t.translationVL
        } as DentalMovementDTO)
      )
    );
  }, [dentalMovementsSteps, isOverMovement, teethTransformDataList]);

  const hasAnyOppositeMovement = useCallback(() => {
    return (
      dentalMovementsSteps.some((step) =>
        step.relativeDentalMovement.some((movement) => isThereOppositeMovement(movement))
      ) ||
      teethTransformDataList.some((t) =>
        isThereOppositeMovement({
          extrusionIntrusion: t.extrusionIntrusion,
          pureRotation: t.pureRotation,
          tip: t.tip,
          torque: t.torque,
          translationMD: t.translationMD,
          translationVL: t.translationVL
        } as DentalMovementDTO)
      )
    );
  }, [dentalMovementsSteps, isThereOppositeMovement, teethTransformDataList]);

  const hasEmptySteps = useCallback(() => {
    const dentalMovementsWithoutInitialStep = dentalMovementsSteps.filter((step) => step.index !== 0);
    return dentalMovementsWithoutInitialStep.some((step) =>
      step.relativeDentalMovement.every(
        (movement) =>
          movement.extrusionIntrusion === 0 &&
          movement.pureRotation === 0 &&
          movement.tip === 0 &&
          movement.torque === 0 &&
          movement.translationMD === 0 &&
          movement.translationVL === 0
      )
    );
  }, [dentalMovementsSteps]);

  const hasAnyExcessiveIpr = useCallback(() => {
    const allIprLabels = [...(lowerDentalMovements?.iprLabels ?? []), ...(upperDentalMovements?.iprLabels ?? [])];
    return allIprLabels.some((ipr) => ipr.value > 0.5);
  }, [lowerDentalMovements, upperDentalMovements]);

  const hasAnyModificationWithoutAutoRecalc = useCallback(() => {
    const allSteps = [...(lowerDentalMovements?.steps ?? []), ...(upperDentalMovements?.steps ?? [])];
    return allSteps.some((s) => s.isModifiedStepWithNoAutoRecalc);
  }, [lowerDentalMovements, upperDentalMovements]);

  const updateDentalMovements = useCallback(
    async (lowerMovements: DentalMovementGroup, upperMovements: DentalMovementGroup) => {
      setLoading(true);
      const allTeeth = [...(lowerMovements?.teeth ?? []), ...(upperMovements?.teeth ?? [])];
      const allIprLabels = [...(lowerMovements?.iprLabels ?? []), ...(upperMovements?.iprLabels ?? [])];
      const groupedSteps = groupObjectsByStepIndex(lowerMovements?.steps, upperMovements?.steps);
      const movementTable = await getMovementsTable();
      const result: EvolutionStep[] = groupedSteps.map((step: Step) => {
        const isUpperPassiveAligner =
          step.stepIndex !== 0 &&
          !upperMovements?.steps
            .find((s) => s.stepIndex === step.stepIndex)
            ?.toothMovements?.some((tm) => tm.movedInCurrentStep);

        const isLowerPassiveAligner =
          step.stepIndex !== 0 &&
          !lowerMovements?.steps
            .find((s) => s.stepIndex === step.stepIndex)
            ?.toothMovements?.some((tm) => tm.movedInCurrentStep);
        const relativeDentalMovement = getRelativeDentalMovementsByStep(step.stepIndex, movementTable);

        return {
          index: step.stepIndex,
          teeth: calculateTeeth(step, allTeeth, isUpperPassiveAligner, isLowerPassiveAligner),
          attachments: calculateAttachments(step, allTeeth),
          interdentalDistances: calculateInterdentalDistances(step, allTeeth, allIprLabels),
          relativeDentalMovement: relativeDentalMovement
        };
      });

      const iprLabelsListToSave = [...(lowerMovements?.iprLabels || []), ...(upperMovements?.iprLabels || [])];
      setDentalMovementsSteps(result);
      setDentalMovementsApplyIPRList(iprLabelsListToSave);
      setLoading(false);
    },
    [calculateAttachments, calculateInterdentalDistances, getMovementsTable, getRelativeDentalMovementsByStep]
  );

  useEffect(() => {
    if (evergineReady && stageIsLoaded && checkMovementUpdates && !loading) {
      updateDentalMovements(lowerDentalMovements, upperDentalMovements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evergineReady, stageIsLoaded, lowerDentalMovements, upperDentalMovements]);

  return {
    dentalMovementsSteps,
    dentalMovementsApplyIPRList,
    setDentalMovementsApplyIPRList,
    groupObjectsByStepIndex,
    hasAnyOvermovement,
    hasEmptySteps,
    hasAnyExcessiveIpr,
    hasAnyModificationWithoutAutoRecalc,
    hasAnyOppositeMovement
  };
}
