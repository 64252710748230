import { useCallback } from 'react';
import { CaptureType } from '../common/evergine';
import { useCommonBoundStore } from '../common/stores/useStore';
import { editCasePhase } from '../common/utils';
import { OrthodonticsOrderedPhasesKeys } from '../orthodontics/components/layout';
import { useOrthBoundStore } from '../orthodontics/stores/useStore';
import { CaptureToUpload, CaseVersionState, FileCaptureType } from '../shared';
import { CasePhase } from '../shared/caseStatus';
import { useBoundStore } from '../surgeries/stores/useStore';
import { useCaseId, useCaseStatus, useGetDentalMovements, useRemoveFile, useUploadFile, useUtils } from './shared';
import { SaveChangesHook } from './shared/useModalSaveChanges';
import {
  EDITED_LOWER_MATRIX_FILE_KEY,
  EDITED_UPPER_MATRIX_FILE_KEY
} from '../orthodontics/components/evergineToolbarElements/tools/stlEdition/stlOrientation';

export function useSTLLoad(): SaveChangesHook {
  const {
    lowerFilesToUpload,
    upperFilesToUpload,
    captureType,
    updateLowerModel3DId,
    updateUpperModel3DId,
    keyFileToDelete,
    updateIsCaseReseting,
    isCaseReseting
  } = useCommonBoundStore((state) => ({
    lowerFilesToUpload: state.lowerFilesToUpload,
    upperFilesToUpload: state.upperFilesToUpload,
    captureType: state.captureType,
    updateLowerModel3DId: state.updateLowerModel3DId,
    updateUpperModel3DId: state.updateUpperModel3DId,
    keyFileToDelete: state.keyFileToDelete,
    updateIsCaseReseting: state.updateIsCaseReseting,
    isCaseReseting: state.isCaseReseting
  }));
  const { currentVersion, setIsCasePublished } = useOrthBoundStore((state) => ({
    currentVersion: state.currentVersion,
    setIsCasePublished: state.setIsCasePublished
  }));
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const setWebBusyMessage = useBoundStore((state) => state.setWebBusyMessage);
  const [caseId] = useCaseId();
  const { uploadFileFromFS } = useUploadFile(caseId);
  const { removeCaptureFile } = useRemoveFile(caseId);
  const { updateTreatmentState } = useGetDentalMovements();
  const { uncompleteNextPhases } = useUtils();
  const { fetchCaseStatus } = useCaseStatus(caseId);

  const updateFileInfo = useCallback(
    (file: CaptureToUpload) => {
      const fileinfo = { ...file };
      if (captureType == CaptureType.MODEL3D) {
        fileinfo.path = fileinfo.path.substring(0, fileinfo.path.lastIndexOf('.')) + '.wepmd';
        fileinfo.name = fileinfo.name.substring(0, fileinfo.name.lastIndexOf('.')) + '.wepmd';
        fileinfo.size = Module.FS.open(fileinfo.path).node.usedBytes;
      }

      return fileinfo;
    },
    [captureType]
  );

  const saveFile = useCallback(
    async (fileToUpload: CaptureToUpload, fnUpdateModel3dId: (newUpperModel3DId: string) => void) => {
      const currentVersionId = currentVersion?.id;
      try {
        if (fileToUpload && currentVersionId) {
          const file = updateFileInfo(fileToUpload);
          await uploadFileFromFS(file, currentVersionId);
          if (file.name.includes('wepmd')) {
            fnUpdateModel3dId(file.name.replace('.wepmd', ''));
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [currentVersion, uploadFileFromFS, updateFileInfo]
  );

  const removeEditedModels = async () => {
    await Promise.all([
      removeCaptureFile(FileCaptureType.UPPER_MODEL3D_EDITED),
      removeCaptureFile(FileCaptureType.UPPER_STL_EDITED),
      removeCaptureFile(FileCaptureType.LOWER_MODEL3D_EDITED),
      removeCaptureFile(FileCaptureType.LOWER_STL_EDITED),
      removeCaptureFile(EDITED_LOWER_MATRIX_FILE_KEY),
      removeCaptureFile(EDITED_UPPER_MATRIX_FILE_KEY)
    ]);
  };

  const saveInBackend = useCallback(async () => {
    const buildPhase: CasePhase = {
      id: OrthodonticsOrderedPhasesKeys.StlLoad,
      name: OrthodonticsOrderedPhasesKeys.StlLoad,
      completionDate: new Date(),
      editionDate: new Date()
    };
    const versionId: string = currentVersion.id.toString();
    await editCasePhase(caseId, versionId, buildPhase);

    if (isCaseReseting) {
      setCanUndo(false);
      updateIsCaseReseting(false);
      await removeEditedModels();
      await uncompleteNextPhases();
      await updateTreatmentState(currentVersion.caseId, currentVersion, CaseVersionState.designing, true);
      await fetchCaseStatus();
      setIsCasePublished(false);
    }
  }, [currentVersion, caseId, isCaseReseting, updateTreatmentState]);

  const saveChanges = useCallback(async (): Promise<boolean> => {
    setWebBusyMessage(undefined);
    setWebBusy(true);
    let response;
    try {
      if (keyFileToDelete) {
        await removeCaptureFile(keyFileToDelete);
      }

      const upperPromises =
        upperFilesToUpload?.map(async (upperFileToUpload) => {
          await saveFile(upperFileToUpload, updateUpperModel3DId);
        }) || [];

      const lowerPromises =
        lowerFilesToUpload?.map(async (lowerFileToUpload) => {
          await saveFile(lowerFileToUpload, updateLowerModel3DId);
        }) || [];

      await Promise.all([...upperPromises, ...lowerPromises]);

      await saveInBackend();

      response = true;
    } catch (error) {
      response = true;
    } finally {
      setWebBusy(false);
      return Promise.resolve(response);
    }
  }, [saveFile, updateUpperModel3DId, currentVersion, keyFileToDelete]);

  return { saveChanges, autoSaveChanges: saveChanges };
}
