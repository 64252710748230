import { useWindowSize } from '.';
import { Constants } from '../../shared';
import { OrthodonticsPagesUrl } from '../../types';
import { useEffect, useMemo, useState } from 'react';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';

const pagesWithoutYOffset: string[] = [
  OrthodonticsPagesUrl.StlEdition,
  OrthodonticsPagesUrl.TeethSegmentation,
  OrthodonticsPagesUrl.AxisAndRoots
];

export type UseCanvasSizeType = {
  canvasHeight: number;
  canvasWidth: number;
  offsetX: number;
};

export function useCanvasSize(pathname: string, currentRoute: string): UseCanvasSizeType {
  const size = useWindowSize();
  const showEvolutionPanel = useOrthBoundStore((state) => state.showEvolutionPanel);
  const [canvasWidth, setCanvasWidth] = useState({ width: size.width, offset: 0 });

  const canvasHeight = useMemo(() => {
    const applyOffset = !pagesWithoutYOffset.includes(currentRoute);
    const offset = applyOffset ? Constants.canvasHeightOffset : 0;
    return size.height - offset;
  }, [size.height, currentRoute]);

  useEffect(() => {
    let observer: MutationObserver | null = null;
    let timeout: NodeJS.Timeout | null = null;

    const calculateCanvasWidth = () => {
      const panel = document.querySelector('.evolutionpanel');
      if (!panel || !showEvolutionPanel) {
        setCanvasWidth({ width: size.width, offset: 0 });
        return;
      }

      const panelWidth = panel.getBoundingClientRect().width;
      const panelStyles = window.getComputedStyle(panel, '::before');
      const beforeWidth = parseFloat(panelStyles.width || '0');
      const totalWidth = panelWidth + beforeWidth;
      const offset = Math.min(totalWidth, size.width / 2);
      setCanvasWidth({ width: size.width - offset, offset });
    };

    const waitForPanel = () => {
      const panel = document.querySelector('.evolutionpanel');
      if (panel) {
        calculateCanvasWidth();

        observer = new MutationObserver(() => {
          calculateCanvasWidth();
        });

        observer.observe(panel, { attributes: true, childList: true, subtree: true });
      } else {
        timeout = setTimeout(waitForPanel, 50);
      }
    };

    if (showEvolutionPanel) {
      waitForPanel();
    } else {
      setCanvasWidth({ width: size.width, offset: 0 });
    }

    return () => {
      if (observer) observer.disconnect();
      if (timeout) clearTimeout(timeout);
    };
  }, [showEvolutionPanel, size.width]);

  const canvasSize = useMemo(
    () => ({ canvasHeight, canvasWidth: canvasWidth.width, offsetX: canvasWidth.offset }),
    [canvasHeight, canvasWidth]
  );

  return canvasSize;
}
