import { useCallback } from 'react';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { OrthodonticsOrderedPhasesKeys } from '../../orthodontics/components/layout';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { TypesActions } from '../../surgeries/components/layout';
import { useSaveAxisAndRoots, useSaveSegmentation, useSaveStlEdition, useSaveTreatment } from '../orthodontics';
import { useSTLLoad } from '../useSTLLoad';
import { useCaseChanges } from './useCaseChanges';
import { useSavePublish } from '../orthodontics/saveChanges/useSavePublish';

export interface SaveChangesHook {
  saveChanges(isNextPhase: boolean): Promise<boolean>;
  autoSaveChanges(): Promise<boolean>;
}

// This is a default hook that will be used when no hook is found for the current phase
// or, when save changes is not required (going back from a step without saving)
const defaultSaveChangesHook: Readonly<SaveChangesHook> = {
  saveChanges: async (): Promise<boolean> => {
    return Promise.resolve(true);
  },
  autoSaveChanges: async (): Promise<boolean> => {
    return Promise.resolve(true);
  }
};

export function useModalSaveChanges(param?: TypesActions) {
  const { getRouteKey } = useCaseChanges();
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);
  const { isCaseReseting, upperFilesToUpload, lowerFilesToUpload, keyFileToDelete } = useCommonBoundStore((state) => ({
    isCaseReseting: state.isCaseReseting,
    upperFilesToUpload: state.upperFilesToUpload,
    lowerFilesToUpload: state.lowerFilesToUpload,
    keyFileToDelete: state.keyFileToDelete
  }));

  const orthodonticsPhasesDictionary: { [key: string]: SaveChangesHook } = {
    [OrthodonticsOrderedPhasesKeys.StlLoad]: useSTLLoad(),
    [OrthodonticsOrderedPhasesKeys.StlEdition]: useSaveStlEdition(),
    [OrthodonticsOrderedPhasesKeys.TeethSegmentation]: useSaveSegmentation(),
    [OrthodonticsOrderedPhasesKeys.AxisAndRoots]: useSaveAxisAndRoots(),
    [OrthodonticsOrderedPhasesKeys.Treatment]: useSaveTreatment(),
    [OrthodonticsOrderedPhasesKeys.Publish]: useSavePublish()
  };

  const getPhaseSaveChange = useCallback(() => {
    const key = getRouteKey();
    if (!key && !param) return defaultSaveChangesHook;
    const phaseFunction = orthodonticsPhasesDictionary[key];
    return phaseFunction;
  }, [currentVersion, param, isCaseReseting, upperFilesToUpload, lowerFilesToUpload, keyFileToDelete]);

  return { getPhaseSaveChange };
}
