import { StateCreator } from 'zustand';

export type TreatmentPhaseChecks = {
  isIprCalcPending: boolean;
  isAttachesCalcPending: boolean;
  isThereAnyOvermovement: boolean;
  isThereAnyEmptyStep: boolean;
  isThereAnyExcessiveIpr: boolean;
  isThereAnyModificationWithoutAutoRecalc: boolean;
  isThereAnyOppositeMovement: boolean;
};

export interface TreatmentPhaseSlice {
  treatmentPhaseIssuesChecks: TreatmentPhaseChecks;
  setTreatmentPhaseIssuesChecks: (treatmentPhaseIssuesChecks: TreatmentPhaseChecks) => void;
}

export const createTreatmentPhaseSlice: StateCreator<TreatmentPhaseSlice, [], [], TreatmentPhaseSlice> = (set) => ({
  treatmentPhaseIssuesChecks: {
    isIprCalcPending: false,
    isAttachesCalcPending: false,
    isThereAnyOvermovement: false,
    isThereAnyEmptyStep: false,
    isThereAnyExcessiveIpr: false,
    isThereAnyModificationWithoutAutoRecalc: false,
    isThereAnyOppositeMovement: false
  },
  setTreatmentPhaseIssuesChecks: (treatmentPhaseIssuesChecks: TreatmentPhaseChecks) =>
    set((_) => ({ treatmentPhaseIssuesChecks }))
});
