import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { ReactComponent as IconDelete } from '../../../assets/icons/delete.svg';
import { ReactComponent as IconMinus } from '../../../assets/icons/minus.svg';
import { ReactComponent as IconPlus } from '../../../assets/icons/plus.svg';
import { useOrthBoundStore } from '../../stores/useStore';
import './buttonsIPR.scss';
import { useUtils } from '../../../hooks';
import { Key } from 'ts-key-enum';

enum OperationType {
  ADD,
  SUBSTRACT
}

export type LabelPosition = {
  x: number;
  y: number;
};

export function IPRButtons() {
  const { iprLabelSelected, showEvolutionPanel } = useOrthBoundStore((state) => ({
    iprLabelSelected: state.iprLabelSelected,
    showEvolutionPanel: state.showEvolutionPanel
  }));
  const { updateMovements } = useUtils();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const webEventsProxyRef = useRef(window.App.webEventsProxy.iprs);
  const [showIpr, setShowIpr] = useState<boolean>(false);

  useEffect(() => {
    setShowIpr(true);
  }, [iprLabelSelected]);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setShowIpr(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === Key.Delete) {
        removeIprLabel();
      }
    },
    [iprLabelSelected]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const handleActionEvergine = useCallback(
    (action: OperationType) => {
      if (!iprLabelSelected) return;

      const newValue = parseFloat(
        (iprLabelSelected.iprDistance + (action === OperationType.ADD ? 0.1 : -0.1)).toFixed(1)
      );
      if (newValue < 0.1) {
        removeIprLabel();
        return;
      }

      webEventsProxyRef.current.updateLabel(iprLabelSelected.leftToothFdi, iprLabelSelected.rightToothFdi, newValue);
      updateMovements();
    },
    [iprLabelSelected, updateMovements]
  );

  const removeIprLabel = useCallback(() => {
    if (!iprLabelSelected) return;

    webEventsProxyRef.current.removeLabel(iprLabelSelected.leftToothFdi, iprLabelSelected.rightToothFdi);
    updateMovements();
    setShowIpr(false);
  }, [iprLabelSelected, updateMovements]);

  const getEffectiveScale = () => {
    const zoomLevel = window.visualViewport?.scale || window.outerWidth / window.innerWidth;
    const devicePixelRatio = window.devicePixelRatio || 1;

    return zoomLevel * devicePixelRatio;
  };

  const calculateIprPosition = useCallback(() => {
    if (!iprLabelSelected) return null;

    const effectiveScale = getEffectiveScale();

    const evolutionPanelEl = document.querySelector('.evolutionpanel');
    const evolutionPanelWidth =
      evolutionPanelEl && showEvolutionPanel
        ? evolutionPanelEl.getBoundingClientRect().width +
          parseFloat(getComputedStyle(evolutionPanelEl, '::before').width)
        : 0;

    const position = {
      x: (iprLabelSelected.position.x + effectiveScale * evolutionPanelWidth) / effectiveScale,
      y: iprLabelSelected.position.y / effectiveScale
    };

    return position;
  }, [iprLabelSelected, showEvolutionPanel]);

  const iprTools = useMemo(() => {
    if (!iprLabelSelected || !showIpr) return null;

    const iprPosition = calculateIprPosition();
    const labelsHalfWidthPx = 15;
    const correctionX = 75;
    const correctionY = 45;
    const styleMenu: CSSProperties = {
      left: `${iprPosition.x + correctionX - labelsHalfWidthPx}px`,
      top: `${iprPosition.y + correctionY + labelsHalfWidthPx}px`,
      backgroundColor: 'transparent!important',
      position: 'absolute'
    };

    return (
      <div ref={wrapperRef} className="contain-ipr-button" key={`ipr-container-${iprLabelSelected.leftToothFdi}`}>
        <Dropdown defaultShow={true} className="ipr-dropdown" autoClose="outside" drop="down" style={styleMenu}>
          <Dropdown.Menu>
            <div className="menu-ipr">
              <button className="tooltip-button" onClick={() => handleActionEvergine(OperationType.SUBSTRACT)}>
                <IconMinus />
              </button>
              <button className="tooltip-button" onClick={() => handleActionEvergine(OperationType.ADD)}>
                <IconPlus />
              </button>
              <div className="vertical-line" />
              <button className="tooltip-button" onClick={removeIprLabel}>
                <IconDelete />
              </button>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }, [iprLabelSelected, showIpr, showEvolutionPanel]);

  return <>{iprTools}</>;
}
